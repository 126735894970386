import * as React from "react"
import Layout from "../components/layout";
import * as Container from "../components/container/container";

const IndexPage = (props) => {
    return (
        <Layout pageTitle="OdD" props={props}>
            <main id="main">
                <Container.HeaderPart background="white no-image">
                    <div className="headline">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <h1>Der Wissenschaftliche Beirat</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container.HeaderPart>
                <Container.Part background="white">
                    <Container.Row>
                        <div className="col-6">
                            <p>
                                <strong><em>
                                    „Auch Demokratie braucht Erinnerung“
                                </em></strong>
                            </p>
                            <p>
                                Der Beirat ist ein überparteiliches Gremium, dessen Mitglieder ausschließlich nach ihrer
                                Expertise und ihrer Funktion ausgewählt wurden. Als ehrenamtliche Aufgabe angelegt,
                                bündelt
                                das Gremium die Fachkompetenz von ausgewiesenen Einrichtungen der politischen Bildungs-
                                und Demokratiearbeit, von Vertretern der Wissenschaft, der kommunalen Spitzenverbände
                                und
                                der Heimatpflege. Mit sachverständiger Beratung und geschichtswissenschaftlicher
                                Fundierung
                                unterstützte der Beirat das Landtagspräsidium insbesondere bei der Identifizierung der
                                vorrangig
                                zu berücksichtigenden Demokratieorte, dem didaktischen Konzept der Wanderausstellung und
                                der Auswahl des Gedenkobjekts.
                            </p>
                        </div>
                    </Container.Row>
                    <Container.Row>
                        <div className="col-12"><h2>Die Vorsitzenden</h2></div>
                        <div className="col-6">
                            <figure className="lightbox">
                                <img src={"/images/Beirat/l.spaenle.jpg"} alt="Dr. Ludwig Spaenle"/>
                                <figcaption>
                                    Foto: Bildarchiv Bayerischer Landtag
                                </figcaption>
                            </figure>
                        </div>
                        <div className="col-6">
                            <h3>Dr. Ludwig Spaenle</h3>
                            <p>Mitglied des Bayerischen Landtags; von 2008 bis 2013 Staatsminister für
                                Unterricht und Kultus, 2013 bis 2018 zusätzlich Staatsminister für Wissenschaft und
                                Kunst. Seit 2018 Beauftragter der Bayerischen Staatsregierung für jüdisches Leben und
                                gegen Antisemitismus, für Erinnerungsarbeit und geschichtliches Erbe
                            </p>
                        </div>
                    </Container.Row>
                    <Container.Row>
                        <div className="col-6">
                            <figure className="lightbox">
                                <img src={"/images/Beirat/f.kramer.jpg"} alt="Prof. Dr. Ferdinand Kramer"/>
                                <figcaption>
                                    Foto: Bildarchiv Bayerischer Landtag
                                </figcaption>
                            </figure>
                        </div>
                        <div className="col-6">
                            <h3>Prof. Dr. Ferdinand Kramer</h3>
                            <p>Seit 2003 Ordinarius für Bayerische Geschichte und Vergleichende Landesgeschichte mit
                                besonderer Berücksichtigung der Neuzeit am Historischen Seminar und Vorstand des
                                Instituts für Bayerische Geschichte an der Ludwig-Maximilians-Universität München; seit
                                2014 1. Vorsitzender der Kommission für bayerische Landesgeschichte bei der Bayerischen
                                Akademie der Wissenschaften
                            </p>
                        </div>
                    </Container.Row>
                    <Container.Row>
                        <div className="col-12">
                            <h2>Die Mitglieder</h2>
                        </div>
                        <div className="col-6">
                            <ul>
                                <li><strong>Florian Besold</strong><br/>Präsident der Bayerischen Einigung e.V</li>
                                <li><strong>Horst Gehringer</strong><br/>Leiter des Stadtarchivs Bamberg</li>
                                <li><strong>Rupert Grübl</strong><br/>Direktor der Landeszentrale für politische
                                    Bildungsarbeit
                                </li>
                                <li><strong>Dr. Thomas Hertfelder</strong><br/>Gründungsgeschäftsführer der
                                    Stiftung<br/>
                                    Bundespräsident-Theodor-Heuss-Haus in Stuttgart
                                </li>
                                <li><strong>Dr. Richard Loibl</strong><br/>Direktor des Hauses der Bayerischen
                                    Geschichte
                                </li>
                                <li><strong>Prof. Dr. Ursula Münch</strong><br/>Direktorin der Akademie für politische
                                    Bildung, Tutzing
                                </li>
                                <li><strong>Dr. Jörg Skriebeleit</strong><br/>Leiter der KZ-Gedenkstätte Flossenbürg
                                </li>
                                <li><strong>Prof. Dr. Manfred Treml</strong><br/>Verband bayerischer Geschichtsvereine
                                    e.V.
                                </li>
                            </ul>
                        </div>
                        <div className="col-6">
                            <figure className="lightbox">
                                <img src={"/images/Beirat/beirat.jpg"} alt="Foto des Beirats"/>
                                <figcaption>
                                    Foto: Bildarchiv Bayerischer Landtag
                                </figcaption>
                            </figure>
                        </div>
                    </Container.Row>
                    <Container.Row>
                        <div className="col-12">
                            <p>Teil des Beirats sind auch Vertreter des:</p>
                            <ul>
                                <li>
                                    <strong>Bayerischen Gemeindetags,</strong><br/>
                                    vertreten durch Gerhard Dix, Direktor, als Vertreter des Präsidenten Dr. Uwe Brandl
                                </li>
                                <li>
                                    <strong>Bayerischen Städtetags,</strong><br/>
                                    vertreten durch Dr. Manfred Riederle, 2. stellv. Geschäftsführer
                                </li>
                                <li>
                                    <strong>Bayerischen Landesvereins für Heimatpflege e. V.,</strong><br/>
                                    vertreten durch Dr. Wolfgang Pledl, Vorsitzender
                                </li>
                                <li>
                                    <strong>Berufsverbands bildender Künstler – Landesverband Bayern e. V.,</strong><br/>
                                    vertreten durch Christian Schnurer, Vorsitzender, und Maike Dieterle, Referentin
                                </li>
                            </ul>
                            <p>
                                Auf Einladung der Beiratsvorsitzenden nimmt an den Gremiumssitzungen außerdem teil<br/>
                                Benita Meißner, Geschäftsführerin und Kuratorin der Deutschen Gesellschaft für
                                christliche Kunst e. V.
                            </p>
                        </div>
                    </Container.Row>
                </Container.Part>
            </main>
        </Layout>
    )
}

export default IndexPage
